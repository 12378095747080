import './style/StoreHeader.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import searchIcon from '../../media/search.png'
import Label from '../block/Label';
import Icon from '../block/Icon';
import Input from '../block/Input';
import WordmarkWhite from '../../media/materialize-wordmark-white.png';
import LogoWhite from '../../media/materialize-logo-white.png';
import Cart from '../../media/cart@3x.png';
import Divider from '../block/Divider'
import Action from '../block/Action';
import { getImgUrl } from '../../util/ImageSource';
import Tile from '../block/Tile';
import Image from '../block/Image';
import { formatToCurrency  } from '../../util/Calculate'
import { setScrollPosition } from '../../redux/localSlice';

const StoreHeader = ({ searchInputValue, setSearchInputValue, cart, showCart, products, handleViewProduct }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [resultsVisible, setResultsVisible] = useState(false);
    const inputRef = useRef(null);
    const resultsWrapperRef = useRef(null);

    const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
    };

    const handleShowCart = () => {
        dispatch(setScrollPosition(0));
        navigate('/cart');
    };

    useEffect(() => {
        if (searchInputValue?.length > 0) {
            const newProducts = products?.filter(product => product?.marketManufacturer?.toLowerCase()?.includes(searchInputValue.toLowerCase()) || product?.marketMpn?.toLowerCase()?.includes(searchInputValue.toLowerCase()));
            setSearchedProducts(newProducts.slice(0, 5));
        } else {
            setSearchedProducts([]);
        }
    }, [searchInputValue]);

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        setResultsVisible(false);
        navigate(`/?search=${searchInputValue}`);
    };

    const handleInputFocus = () => {
        console.log('Input focused');
        setResultsVisible(true);
    };

    // Listen for clicks outside the component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                resultsWrapperRef.current &&
                !resultsWrapperRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setResultsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const viewProduct = (product) => {
        handleViewProduct(product);
        setResultsVisible(false);
        setSearchInputValue('');
    }

    const handleGoHome = () => {
        dispatch(setScrollPosition(0));
        navigate('/');
    };

    return (
        <div id="store-header" className="flex horizontal width-100 space-between align-center pad-m no-pad-top no-pad-bot h-gap-m">
            <h1 className='hidden-section'>
                Materialize Shop - Certified Manufacturer's Excess Inventory
            </h1>
            <h2 className='hidden-section'>
                Welcome to the Materialize Shop, where you can find certified manufacturer's excess inventory. We offer a wide range of products from various manufacturers, all at a discounted price. Our products are certified and guaranteed to be in working condition or your money back.
            </h2>
            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={"Materialize Shop - Certified Excess"} />
            <meta property="og:description" content={"Shop for parts sourced directly from certified manufacturers' excess inventory. Select from a wide range of products from various manufacturers, all at a discounted price."} />
            <meta property="og:image" content={LogoWhite} />
            <meta property="og:url" content={"https://shop.trymaterialize.com"} />
            <meta name="twitter:title" content="Materialize Shop - Certified Excess" />
            <meta name="twitter:description" content="Shop parts sourced directly from certified manufacturers' excess inventory." />
            <meta name="twitter:url" content="https://shop.trymaterialize.com" />
            <meta name="twitter:card" content="Shop from a wide range of products from various certified manufacturers' inventory, all at a discounted price."></meta>
            <div className="vertical flex height-100 pad-s relative justify-center">
                <button id="product-home-button" className="flex vertical height-100" onClick={handleGoHome}>
                    <Image altTag="Materialize Logo" fullHeight={true} whiteBg={false} />
                </button>
            </div>
            <div id="product-search-wrapper" className={`relative ${searchedProducts?.length > 0 ? "results" : ""}`}>
                <Icon customId="product-search-icon" icon={searchIcon} color="white" size="24" />
                <Input customId="product-search-input" inputRef={inputRef} onKeyDown={handleEnterKeyPress} onChange={handleSearchInputChange} value={searchInputValue} placeholder="Search" flex={true} onBrand={true} onFocus={handleInputFocus} />
                {(resultsVisible && searchInputValue?.length > 0) && (
                    <div id="product-search-results-wrapper" ref={resultsWrapperRef} className="flex vertical keystone-card">
                        {(searchInputValue?.length > 0 && searchedProducts?.length > 0) && (
                            <div className="flex vertical">
                                <div className="flex vertical align-center pad-xs v-gap-s">
                                    <Action label={`View all products matching '${searchInputValue}'`} onClick={handleSearch} flex={true} />
                                </div>
                                <Divider vertical={false} />
                            </div>
                        )}
                        {searchedProducts?.length > 0 ? searchedProducts?.map((product, index) => (
                            <Tile
                                key={index + product.marketMpn}
                                index={index + product.marketMpn}
                                onClick={() => viewProduct(product)}
                                rowStyle={true}
                                content={
                                    <div className="flex horizontal height-100 h-gap-m pad-xs align-center">
                                        <Image source={getImgUrl(product)} altTag={product.marketMpn} width="medium" />
                                        <div className="flex horizontal flex-1 space-between">
                                        <div className="flex vertical">
                                            <Label content={product.marketManufacturer || ""} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                            <Label content={product.marketMpn} size="m" mono={true} />
                                        </div>
                                        <div className="flex vertical">
                                            <Label content={`${product.quantity} Units`} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                            <Label content={formatToCurrency(product.price)} size="m" mono={true} />
                                        </div>
                                        </div>
                                    </div>
                                }
                            />
                        )) : (
                            <div className="flex vertical align-center pad-s v-gap-s">
                                <Label content="No products found matching that search" size="s" bold={true} displayFont={true} center={true} secondary={true} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="vertical flex height-100 pad-xs relative justify-center">
                <button id="cart-button" className={`relative ${showCart ? "active" : ""}`} onClick={handleShowCart}>
                    {cart.length > 0 && <div id="cart-count" className="flex align-center justify-center"><Label content={cart.length} mono={true} size="xs" bold={true} /></div>}
                    <img id="cart-logo" src={Cart} alt="Cart Icon" />
                </button>
            </div>
        </div>
    );
};

export default StoreHeader;