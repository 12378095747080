import './style/Store.css';
import React, { useEffect, useState, useRef } from 'react';
import store from './store.json'
import storeTwo from './store-m-2.json'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoreHeader from '../component/store/StoreHeader';
import StoreSidebar from '../component/store/StoreSidebar';
import Toast from '../component/block/Toast';
import Button from '../component/block/Button';
import CookieSettings from '../component/block/CookieSettings';
import Label from '../component/block/Label';
import StoreContent from '../component/store/StoreContent';
import { setCart, setCookies, setScrollPosition } from '../redux/localSlice';
import { getImgUrl } from '../util/ImageSource';
import LogRocket from 'logrocket';
import Clarity from '@microsoft/clarity';
import posthog from 'posthog-js'

const Store = () => {
    const dispatch = useDispatch();
    const local = useSelector((state) => state.local);

    const products = store;
    const productsTwo = storeTwo;
    const [storeProducts, setStoreProducts] = useState(products);
    const [currentProducts, setCurrentProducts] = useState(products);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [currentProduct, setCurrentProduct] = useState(null);
    const [showCart, setShowCart] = useState(false);
    const [categories, setCategories] = useState(null);
    const [categoryPaths, setCategoryPaths] = useState([]);
    const [currentCategories, setCurrentCategories] = useState([]);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showAddedToCartToast, setShowAddedToCartToast] = useState(false);
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const [alsoViewedProducts, setAlsoViewedProducts] = useState([]);

    const [showCookieModal, setShowCookieModal] = useState(false);
    const [cookieModalVisible, setCookieModalVisible] = useState(false);
    const timeoutRef = useRef(null);


    const isLocalhost = window.location.origin.includes('localhost') ? true : false;
    if (!isLocalhost) {
        Clarity.init("pyk6kd2un8");
        posthog.init('phc_NGGCjf8a2ZLnMRrPhBqOI0pD9UbnTQklPmSsuGNxzEb',
            {
                api_host: 'https://us.i.posthog.com',
                person_profiles: 'always' // or 'identified_only' to not create profiles for anonymous users
            }
        );
    }
    
    const storeScrollPosition = () => {
        const scrollViewElement = document.getElementById("scroll-view");
        if (scrollViewElement) {
            dispatch(setScrollPosition(scrollViewElement.scrollTop));
        }
    }
    const handleViewProduct = (product) => {
        storeScrollPosition();

        setCurrentProduct(product);

        // randomize 5 products in the same category
        const alsoViewed = storeProducts.filter((storeProduct) => storeProduct.marketMpn !== currentProduct?.marketMpn).sort(() => 0.5 - Math.random()).slice(0, 4);
        setAlsoViewedProducts(alsoViewed);
        navigate(`${encodeURIComponent(product.marketManufacturer?.toLowerCase())}/${encodeURIComponent(product.marketMpn?.toLowerCase())}`);
    };

    useEffect(() => {
        const alsoViewed = storeProducts.filter((storeProduct) => storeProduct.marketMpn !== currentProduct?.marketMpn).sort(() => 0.5 - Math.random()).slice(0, 4);
        setAlsoViewedProducts(alsoViewed);
    }, [currentProduct]);

    useEffect(() => {
        const scrollViewElement = document.getElementById("scroll-view");
        if (scrollViewElement) {
            scrollViewElement.scrollTop = local.scrollPosition
        }
    }, [currentProducts, local.scrollPosition]);

    useEffect(() => {
        if (!categories) {
            const categoryStructure = [];
            const categoryMap = {};

            const otherProducts = productsTwo.filter((product) => product.marketMpn?.length > 0 && product.description?.length > 0).map((product) => {
                let marketMpn = product.marketMpn?.[0] === "-" ? product.marketMpn.substring(1) : product.marketMpn;
                // title case the market mpn
                marketMpn = marketMpn.split(" ").map((word) => word[0]?.toUpperCase() + word.substring(1)).join(" ");
                return {
                    ...product,
                    marketMpn: marketMpn
                }
            });
            const mergedProducts = [...products, ...otherProducts];

            mergedProducts.forEach((product) => {
                const category = product.category;
                if (category) {
                    const categoryPath = product?.categoryPath !== "test-equipment" ? "electronic-components/" + product.categoryPath : product.categoryPath;

                    if (categoryPath) {
                        // Change delimiter from ' > ' to '/'
                        const categoryPathArray = categoryPath.split('/').filter(Boolean); // Use filter(Boolean) to remove empty strings

                        let currentCategory = categoryStructure;
                        categoryPathArray.forEach((categoryName, index) => {
                            // Use an identifier to distinguish, for example, using full path segment
                            const identifier = categoryPathArray.slice(0, index + 1).join('/');

                            if (!categoryMap[identifier]) {
                                categoryMap[identifier] = {
                                    name: categoryName, 
                                    children: []
                                };
                                currentCategory.push(categoryMap[identifier]);
                            }

                            currentCategory = categoryMap[identifier].children;
                        });

                        // Assuming you want to attach the product to its final category node
                        currentCategory.push({
                            ...product, 
                            name: product.category // Add product name if necessary
                        });
                    }
                }
            })
            
            const newProducts = mergedProducts.map((product) => {
                const newPath = product?.categoryPath !== "test-equipment" ? "electronic-components/" + product.categoryPath : product.categoryPath;
                return {
                    ...product,
                    categoryPath: newPath
                }
            });

            setCategories(categoryStructure);
            setCurrentCategories(categoryStructure);

            // sort new products where items with lowestMarketPrice set to the top - it may not be set for all products
            const sortedThings = newProducts.sort((a, b) => (b.lowestMarketPrice || 0) - (a.lowestMarketPrice || 0));

            // set the store products where products that return getImageUrl are set to the top
            setStoreProducts(sortedThings.sort((a, b) => {
                const imageUrlA = getImgUrl(a);
                const imageUrlB = getImgUrl(b);

                if (imageUrlA.includes("placeholder.png") && !imageUrlB.includes("placeholder.png")) {
                    return 1;
                } else if (!imageUrlA.includes("placeholder.png") && imageUrlB.includes("placeholder.png")) {
                    return -1;
                } else if (imageUrlA.includes("placeholder.png") && imageUrlB.includes("placeholder.png")) {
                    return 0;
                } else if (getImgUrl(a) && !getImgUrl(b)) {
                    return -1;
                } else if (!getImgUrl(a) && getImgUrl(b)) {
                    return 1;
                } else {
                    return 0;
                }
            }));
        }
    }, [products]);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if (searchParams && searchParams.has('search') && searchParams.get('search').length > 0) {
            const paramValue = searchParams.get('search');
            if (paramValue?.length > 0) {
                setSearchInputValue(paramValue);
                setCurrentProducts(storeProducts?.filter(product => product?.marketManufacturer?.toLowerCase()?.includes(paramValue.toLowerCase()) || product?.marketMpn?.toLowerCase()?.includes(paramValue.toLowerCase())));
            }
        } else {
            setSearchInputValue('');
            setCurrentProducts(storeProducts);
        }
    }, [storeProducts, search]);

    useEffect(() => {
        const categoryPath = pathname.startsWith("/") ? pathname.substring(1) : pathname;

        if (categoryPath?.includes("cart")) {
            setShowCart(true)
        } else {
            setShowCart(false)
        }

        if (categoryPath?.length > 0 && categories?.length > 0) {
            const newProducts = storeProducts?.filter((product) => product.categoryPath?.includes(categoryPath));

            const categoryPaths = categoryPath.split('/').filter(segment => segment.length > 0);

            const possibleProduct = storeProducts?.find((product) => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === categoryPaths[0] && encodeURIComponent(product.marketMpn?.toLowerCase()) === categoryPaths[1]);
            if (possibleProduct && categoryPaths?.length === 2) {
                setCurrentProduct(possibleProduct);
            } else {
                setCurrentProduct(null);
            }

            let currentCategoryParent = categories;
            categoryPaths.forEach((categoryPath) => {
                currentCategoryParent = currentCategoryParent?.find((category) => category.name === categoryPath)?.children;
            });

            const possibleProducts = storeProducts?.filter(product => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === categoryPaths[0]);

            // only set if first category path is the name of a top level category
            if (possibleProduct || possibleProducts?.length > 0 || categories?.find((category) => category.name === categoryPaths[0])) {
                setCurrentCategories(currentCategoryParent);
                setCategoryPaths(categoryPaths);

                if (!possibleProducts || possibleProducts?.length === 0) {
                    setCurrentProducts(newProducts);
                } else {
                    setCurrentProducts(possibleProducts);
                }
            } else if (!possibleProduct && !categoryPath?.includes("cart")) {
                navigate('/');
            }
        } else if (categoryPath?.length === 0 && search?.length === 0) {
            setCurrentProducts(storeProducts);
            setCurrentCategories(categories);
            setCurrentProduct(null);
            setCategoryPaths([]);
            setSearchInputValue('');
        }
    }, [pathname, categories]);

    const handleCategoryChange = (category) => {
        dispatch(setScrollPosition(0));
        const rootPath = (!pathname || pathname === "/") ? "" : pathname;
        navigate(`${rootPath}/${category.name}`);
        if (category?.children?.length > 0) {
            setCurrentCategories(category?.children);
        }
        setCurrentProduct(null);
    }

    const handleCategoryChangeNew = (categoryName) => {
        const rootPath = (!pathname || pathname === "/") ? "/" : pathname;
    
        if (rootPath.includes(categoryName)) {
            // Find the index of the category name in the rootPath
            const categoryIndex = rootPath.indexOf(categoryName);
    
            // Strip everything after the category name
            dispatch(setScrollPosition(0));
            navigate(`${rootPath.substring(0, categoryIndex + categoryName.length)}`);
        }
    }

    const addToCart = (product, quantity) => {
        const existingCartItem = local.cart.find((cartItem) => cartItem.product.marketMpn === product.marketMpn && cartItem.product.marketManufacturer === product.marketManufacturer);
        if (existingCartItem) {
            const newCart = local.cart.map((cartItem) => {
                if (cartItem.product.marketMpn === product.marketMpn && cartItem.product.marketManufacturer === product.marketManufacturer) {
                    return {
                        product: cartItem.product,
                        quantity: cartItem.quantity + quantity
                    };
                }
                return cartItem;
            });
            dispatch(setCart(newCart));
            return;
        } else {
            dispatch(setCart([...local.cart, { product, quantity }]));
        }
    };

    useEffect(() => {
        if (showSuccessToast) {
            setTimeout(() => {
                setShowSuccessToast(false);
            }, 4000);
        }
    }, [showSuccessToast]);

    useEffect(() => {
        if (showAddedToCartToast) {
            setTimeout(() => {
                setShowAddedToCartToast(false);
            }, 4000);
        }
    }, [showAddedToCartToast]);

    const handleShowCookieModal = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setCookieModalVisible(true);
        setShowCookieModal(true);
        const landing = document.getElementById('landing');
        if (landing) {
          landing.classList.add('overflow-y-hidden');
        }
    };
    
    const handleHideCookieModal = () => {
        setShowCookieModal(false);    
        timeoutRef.current = setTimeout(() => {
            setCookieModalVisible(false);
            const landing = document.getElementById('landing');
            if (landing) {
            landing.classList.remove('overflow-y-hidden');
            }
        }, 700);
    };

    const handleSetCookies = (setting) => {
        if (!window.location.origin.includes("localhost")) {
            if (setting === "all" || setting === "analytics") {
                const scriptOne = document.createElement("script");
                scriptOne.src = "https://www.googletagmanager.com/gtag/js?id=G-VC0BWS1RKL";
                scriptOne.async = true;
                document.head.appendChild(scriptOne);

                const scriptTwo = document.createElement("script");
                scriptTwo.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-VC0BWS1RKL');
                `;
                document.head.appendChild(scriptTwo);
    
                LogRocket.init('o4yyvv/materialize-shop');
            }

            if (setting === "all" || setting === "analytics") {
                window.plugSDK.init({
                    app_id: 'DvRvStPZG9uOmNvcmU6ZHZydi11cy0xOmRldm8vMWFaRHBMS1pXVzpwbHVnX3NldHRpbmcvMV9ffHxfXzIwMjQtMTItMDMgMDU6MDI6MjEuMDgxNzM0Mzc5ICswMDAwIFVUQw==xlxendsDvRv',
                    widget_alignment: "left",
                    title: "Chat with us",
                    primary_text_color: "#000000"
                });
    
                window.plugSDK.onEvent((payload) => {
                    // if (payload.type === "ON_PLUG_WIDGET_OPENED") {
                    //     window.plugSDK.openWidget('conversations_list_tab');
                    // } 
                });
            }
        }
        handleHideCookieModal();
        dispatch(setCookies(setting));
    }

    return (
        <div id="store" className="flex vertical overflow-hidden relative">
            {cookieModalVisible && <CookieSettings handleSetCookies={handleSetCookies} showCookieModal={showCookieModal} handleHideCookieModal={handleHideCookieModal} classes={`${showCookieModal ? "fade-in" : "fade-out no-pointer"}`} />}
            <StoreHeader
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                cart={local.cart}
                products={storeProducts}
                handleViewProduct={handleViewProduct}
                showCart={showCart} />
            <div className="flex vertical flex-1 width-100 overflow-hidden">
                <div className="flex horizontal flex-1 width-100 overflow-hidden">
                    <StoreSidebar
                        currentCategories={currentCategories}
                        categoryPaths={categoryPaths}
                        handleCategoryChange={handleCategoryChange}
                        handleCategoryChangeNew={handleCategoryChangeNew}
                        storeProducts={storeProducts} />
                    <StoreContent
                        showCart={showCart}
                        showAddedToCartToast={showAddedToCartToast}
                        setShowAddedToCartToast={setShowAddedToCartToast}
                        cart={local.cart}
                        handleViewProduct={handleViewProduct}
                        currentProducts={currentProducts}
                        storeProducts={storeProducts}
                        handleCategoryChangeNew={handleCategoryChangeNew}
                        alsoViewedProducts={alsoViewedProducts}
                        categoryPaths={categoryPaths}
                        currentProduct={currentProduct}
                        addToCart={addToCart}
                        searchInputValue={searchInputValue}
                        setShowSuccessToast={setShowSuccessToast} />
                </div>
            </div>
            <div id="keystone-user-toasts-wrapper" className="mobile-bump-pad">
                <div className="flex vertical flex-1 v-gap-s pad-l align-center">
                    {showSuccessToast && <Toast message="Order Request Submitted Successfully" type="user" state="positive" />}
                    {showAddedToCartToast && <Toast message="Added to your cart" type="user" state="positive" />}
                </div>
            </div>
            {local?.cookies === "unset" && (
                <div id="cookie-banner">
                    <div className="blue-bg flex horizontal mobile-vertical pad-xl h-gap-xl v-gap-m space-between align-center">
                        <div className="flex vertical v-gap-xs">
                            <Label content="We value your privacy" size="l" bold={true} line="l" />
                            <Label content='We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.' size="s" mono={true} line="s" />
                        </div>
                        <div className="flex horizontal h-gap-m mobile-width-100">
                            <Button label="Customize" secondary={true} classes="white-bg no-shadow no-border" large={true} onClick={handleShowCookieModal} mobileFlex={true} />
                            <Button label="Accept All" large={true} classes="yellow-bg" onClick={() => handleSetCookies("all")} mobileFlex={true} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Store;