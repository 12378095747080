import './style/Product.css';
import React, { useEffect, useState } from 'react';
import Label from '../component/block/Label';
import ChipRounded from '../component/block/ChipRounded';
import Button from '../component/block/Button';
import LabelInput from '../component/block/LabelInput';
import Divider from '../component/block/Divider';
import CollapsableSection from '../component/block/CollapsableSection';
import { formatToCurrencyExpanded, formatPercentWithCommas, formatNumberWithCommas, formatToCurrency } from '../util/Calculate';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getImgUrl } from '../util/ImageSource';
import Image from '../component/block/Image';
import Tile from '../component/block/Tile';
import Modal from '../component/block/Modal';
import Expand from '../media/expand@3x.png';
import Close from '../media/close@3x.png';

const Product = ({ currentProduct, addToCart, cart, handleViewProduct, alsoViewedProducts, showAddedToCartToast, setShowAddedToCartToast }) => {
    const local = useSelector((state) => state.local);
    const [quantity, setQuantity] = useState(1);
    const [showImageModal, setShowImageModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleQuantityInputChange = (e) => {
        const value = e.target.value;
        // Use a regex to ensure all input is numeric
        if (/^\d+$/.test(value) && parseInt(value) <= (currentProduct?.quantity - (local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity || 0))) {
            setQuantity(parseInt(value));
        } else if (value === '') {
            // Allow empty input to reset the field
            setQuantity('');
        }
    };

    const handleBuyNow = () => {
        // make button load for a fake 0.14 seconds
        setButtonLoading(true);
        setTimeout(() => {
            setButtonLoading(false);
            setShowAddedToCartToast(true);
        }, 700);
        addToCart(currentProduct, quantity);
        setQuantity(1);
    };

    // const handleBuyNow = async () => {
    //         // The data you want to send in the request body
    //     const requestData = {
    //         // Add your request data here
    //         // For example, you may need to send an amount, currency, or other metadata required by your backend
    //         amount: 1000,
    //         currency: 'usd',
    //         // any additional fields
    //     };
        
    //     try {
    //         // Perform the POST request
    //         const response = await fetch('http://localhost:8080/stripe/create_checkout_session', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         // Check if the request was successful
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         // Parse the response body
    //         const data = await response.json();

    //         // Handle the session ID or other response data as needed
    //         console.log('Checkout session data:', data);

    //         // If your backend returns a URL for Stripe Checkout, you can redirect the user to it:
    //         if (data.url) {
    //             window.location.href = data.url;
    //         }

    //     } catch (error) {
    //         // Handle errors here
    //         console.error('There was a problem with the checkout session request:', error);
    //     }
    // };

     // Extract necessary specification values
    const sku = currentProduct?.marketMpn;
    const brand = currentProduct?.marketManufacturer;
    const title = `${brand} - ${sku}`;
    const description = `In-Stock & Ready to Ship: ${currentProduct?.quantity} units of ${brand} - ${sku}. ${currentProduct?.description || 'All stock sourced from our verified network of manufacturers.'}`;

    const availability = currentProduct?.quantity > 0 ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock';
    const condition = 'http://schema.org/NewCondition';
    const currency = 'USD'; // Adjust this if needed
    const url = `https://shop.trymaterialize.com/${encodeURIComponent(currentProduct.marketManufacturer.toLowerCase())}/${encodeURIComponent(currentProduct.marketMpn.toLowerCase())}`;

    // Build structured data
    const structuredData = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: title,
        description: description,
        sku: sku,
        mpn: sku,
        brand: {
            '@type': 'Brand',
            name: brand,
        },
        category: currentProduct?.categoryPath,
        offers: {
            '@type': 'Offer',
            url: url,
            priceCurrency: currency,
            price: currentProduct?.price?.toFixed(2),
            itemCondition: condition,
            availability: availability,
        },
    };

    const specifications = () => {
        return (
            <div className="flex vertical v-gap-m pad-l">
                {currentProduct?.specifications?.length > 0 && <Label content="Specifications" bold={true} size="m" displayFont={true} />}
                <div className="flex vertical v-gap-xs">
                    {currentProduct?.specificationsByType && Object.keys(currentProduct?.specificationsByType).map((type, index) => (
                        <CollapsableSection key={index} label={type} content={
                            <div className="flex vertical v-gap-s pad-s">
                                {currentProduct?.specificationsByType[type].map((spec, index) => (
                                    <div key={index} className="flex horizontal flex-1 width-100 h-gap-xl space-between align-center">
                                            <Label content={spec.name} size="s" bold={true} displayFont={true} secondary={true} maxContent={true} />
                                            <Label content={spec.value + spec.units} size={"s"} mono={true} flex={1} right={true} />
                                    </div>
                                ))}
                            </div>
                        } />
                    ))}
                </div>
            </div>
        );
    }

    const getAlsoViewedProducts = () => {
        return alsoViewedProducts.map((product, index) => (
            <Tile
                key={index + product.marketMpn}
                index={index + product.marketMpn}
                onClick={() => handleViewProduct(product)}
                content={
                    <div className="flex vertical pad-m height-100 space-between">
                        <div className="flex vertical">
                            <Label content={product.marketManufacturer || ""} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                            <Label content={product.marketMpn} size="m" mono={true} />
                        </div>
                        <Image source={getImgUrl(product)} altTag={product.marketMpn} fullWidth={true} overlayContent={product.percentOfMarketPrice !== 0 && (
                            <ChipRounded positive={true} small={true} label={`${formatPercentWithCommas(product.percentOfMarketPrice)} Below Market`} />
                        )} />
                        <div className="flex vertical">
                            <Label content={`${product.quantity} Units`} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                            <Label content={formatToCurrency(product.price)} size="m" mono={true} />
                        </div>
                    </div>
                } />
        ));
    };

    return (
        <>
            <Helmet>
                <title>{`${title} | Materialize`}</title>
                <meta name="description" content={description} />
                <meta name="sku" content={sku} />
                <meta name="mpn" content={sku} />
                <meta name="brand" content={brand} />
                <meta name="price" content={currentProduct?.price?.toFixed(2)} />
                <meta name="availability" content={currentProduct?.quantity > 0 ? 'In Stock' : 'Out of Stock'} />
                <meta name="title" content={title} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={getImgUrl(currentProduct)} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="product" />
                <meta property="product:price:amount" content={currentProduct?.price?.toFixed(2)} />
                <meta property="product:price:currency" content="USD" />
                <meta property="product:availability" content={currentProduct?.quantity > 0 ? 'in stock' : 'out of stock'} />

                {/* Structured Data */}
                <script type="application/ld+json">
                {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className="flex horizontal mobile-vertical flex-1 v-gap-m width-100 h-gap-s">
                <div className="flex vertical flex-2 mobile-flex-reset v-gap-xl">
                    <div className="flex vertical v-gap-s">
                        <div className="flex horizontal align-center flex-1 keystone-card">
                            <div className="flex vertical flex-1 align-center justify-center v-gap-xs">
                                <Image
                                    customId="product-page-image"
                                    source={getImgUrl(currentProduct)}
                                    altTag={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`}
                                    fullWidth={true}
                                    overlayContent={(
                                        <Button icon={Expand} iconColor="#6f7aa5" iconSize={20} onClick={() => setShowImageModal(true)} />
                                    )}
                                    />
                            </div>
                            <div className="flex vertical flex-2 v-gap-s pad-l">
                                <div className="flex vertical v-gap-xxs">
                                    <Label content={currentProduct?.marketManufacturer} bold={true} mono={true} size="s" secondary={true} uppercase={true} />
                                    <Label content={currentProduct?.marketMpn} mono={true} size="display" uppercase={true} />
                                    {currentProduct?.specifications?.some(spec => spec.name === "Case/Package") && (
                                        <ChipRounded label={currentProduct?.specifications?.find(spec => spec.name === "Case/Package")?.value} secondary={true} small={true} />
                                    )}
                                    {currentProduct?.aspects?.rohs_compliant && (
                                        <ChipRounded label="RoHS" secondary={true} small={true} />
                                    )}
                                    {currentProduct?.aspects?.lead_free && (
                                        <ChipRounded label="Lead Free" secondary={true} small={true} />
                                    )}
                                </div>
                                <Label content={currentProduct?.description} size="s" mono={true} line="s" />
                            </div>
                        </div>
                        {currentProduct?.specifications?.length > 0 && (
                            <div className="flex vertical flex-1 keystone-card-secondary mobile-vertical reverse mobile-hide">
                                {specifications()}
                            </div>
                        )}
                    </div>
                    <div className="flex vertical v-gap-s mobile-hide">
                        <div className="flex vertical">
                            <Label content="People also viewed" size="m" bold={true} displayFont={true}/>
                        </div>
                        <div className="grid">
                            {getAlsoViewedProducts()}
                        </div>
                    </div>
                </div>
                <div className="flex vertical flex-1 v-gap-xl">
                    <div className="flex horizontal mobile-vertical h-gap-m">
                        <div id="order-add" className="flex vertical flex-1 keystone-card">
                            <div className="flex vertical v-gap-m pad-l">
                                <div className="flex vertical v-gap-s">
                                    <div className="flex vertical v-gap-xs">
                                        <Label content={`${formatNumberWithCommas(currentProduct?.quantity - (local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity || 0))} Available New In-Box`} size="s" mono={true} uppercase={true} bold={true} secondary={true} />
                                        <Label content={`${formatToCurrencyExpanded(currentProduct?.price)}`} size="display" mono={true} />
                                        {currentProduct?.percentOfMarketPrice !== 0 && (
                                            <ChipRounded label={`${formatPercentWithCommas(currentProduct?.percentOfMarketPrice)} Below Market Price`} positive={true} small={true} />
                                        )}
                                    </div>
                                    {currentProduct?.lowestMarketPriceQuantity > 0 && <Label content={`${formatNumberWithCommas(currentProduct?.lowestMarketPriceQuantity)} Available for ${formatToCurrencyExpanded(currentProduct?.lowestMarketPrice)} from Authorized Resellers`} size="s" displayFont={true} bold="true" secondary={true} />}
                                </div>
                                <div className="flex vertical h-gap-m v-gap-s">
                                    <div className="flex vertical flex-1 v-gap-xxs">
                                        <Label content="Stock Available" size="s" bold={true} displayFont={true}/>
                                            <div className="flex vertical keystone-card-secondary no-shadow">
                                            {currentProduct?.stock?.map((stock, index) => (
                                                <div key={index} className="flex vertical">
                                                    <div className="flex horizontal h-gap-xs align-center space-between">
                                                        <Label content={`${formatNumberWithCommas(stock.quantity)}`} size="s" bold={true} secondary={true} mono={true} flex={1} center={true} pad="xs" />
                                                        <Divider vertical={true} />
                                                        <Label content={`${stock.country}`} size="s" bold={true} secondary={true} mono={true} flex={2} center={true} />
                                                    </div>
                                                    {index < currentProduct?.stock?.length - 1 && <Divider vertical={false} />}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex vertical v-gap-xxs">
                                        <Label content="Date Code" size="s" bold={true} displayFont={true}/>
                                        <div className="flex vertical keystone-card-secondary no-shadow pad-xs">
                                            <Label content={currentProduct?.dc ? currentProduct?.dc : "On Order Request"} size="s" bold={true} secondary={true} mono={true} flex={2} center={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider vertical={false} />
                            <div className="flex vertical v-gap-m mobile-vertical no-shadow pad-l brand">
                                <div className="flex horizontal space-between">
                                    <Label content="Add to Order" size="m" bold={true} displayFont={true}/>
                                    {local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity > 0 && (
                                        <ChipRounded label={`${local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity} In Cart`} small={true} positive={true} />
                                    )}
                                </div>
                                <LabelInput label="Quantity" onChange={handleQuantityInputChange} value={quantity} onWhite={true} type="number" />
                                {/* <form action="localhost:8080/stripe/create_checkout_session" method="POST"> */}
                                <Button loading={buttonLoading} onClick={handleBuyNow} type="submit" label={0 === (currentProduct?.quantity - (local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity || 0)) ? "Out of Stock" : `Add to Order ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(quantity * currentProduct?.price)}`} brand={true} large={true} flexHorizontal={true} disabled={0 === (currentProduct?.quantity - (local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity || 0)) || !parseInt(quantity) || parseInt(quantity) < 1 || buttonLoading} />
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                    {currentProduct?.specifications?.length > 0 && (
                        <div className="flex vertical flex-1 mobile-flex-reset keystone-card-secondary mobile-vertical reverse desktop-hide">
                            {specifications()}
                        </div>
                    )}
                    <div className="flex vertical v-gap-s desktop-hide">
                        <div className="flex vertical">
                            <Label content="People also viewed" size="m" bold={true} displayFont={true}/>
                        </div>
                        <div className="grid flex-1 h-gap-s v-gap-m">
                            {getAlsoViewedProducts()}
                        </div>
                    </div>
                </div>
            </div>
            {showImageModal && <Modal showModal={showImageModal} setShowModal={setShowImageModal} content={(
                <Image
                    customId="modal-image"
                    source={getImgUrl(currentProduct)}
                    altTag={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`}
                    fullWidth={true}
                    overlayContent={(
                        <Button icon={Close} iconColor="#6f7aa5" iconSize={20} />
                    )} />
            )}/>}
        </>
    );
};

export default Product;